import { useJurisdictionContext } from "@taxscribe/ui";
import React, { FC } from "react";
import { Navigate } from "react-router-dom";

const DashboardRedirect: FC = () => {
  const { webPath } = useJurisdictionContext();

  console.log(webPath);
  return (
    <Navigate to={`/${webPath}/dashboard`} replace/>
  );
};

export default DashboardRedirect;
