import React, { FC } from "react";
import { useParams } from "react-router-dom";
import IndividualPT50APage from "./IndividualPT50A";
import IndividualPT50MPage from "./IndividualPT50M";
import NCIPPListingPage from "./NCIPPListing";

export interface FormPageProps {
}

const FormSwitch: FC = () => {
  const { state = "", formType = "" } = useParams();

  switch (`${state.toLowerCase()} ${formType.toLowerCase()}`) {
  case "nc ipp-listing":
    return <NCIPPListingPage />;
  case "ga individual-pt50a":
    return <IndividualPT50APage />;
  case "ga individual-pt50m":
    return <IndividualPT50MPage />;
  default:
    return <div>An error occurred</div>;
  }
};

export default FormSwitch;
