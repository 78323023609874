import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ApolloClient } from "@apollo/client";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import { useAuth } from "@reasongcp/react-fire-sub";
import { useJurisdictionContext } from "@taxscribe/ui";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

import config from "../../../config";
import useExistingFormData from "../../../hooks/useExistingFormData";
import createFormView from "../createFormView";
import { GET_MY_FORM, CurrentSubmission, FormDataUnion } from "@taxscribe/gql-queries";
import LoadingPlaceholder from "../LoadingPlaceholder";

interface ExistingFormArgs {
  formUuid: string;
}

export interface FetchPrePopDataArgs {
  email: string;
  jurisdictionId: number;
  client: ApolloClient<any>;
  accountNumberPrimary: string;
}

export type PrePopFetcher = (args: FetchPrePopDataArgs) => Promise<unknown>;
export type FormDataInjector = (args: {
  formData?: Record<string, unknown> | null;
  formUuid?: string | null;
  currentSubmission?: CurrentSubmission;
}) => Record<string, any> | null | undefined;

interface CreateFormPageArgs {
  prePopForm?: PrePopFetcher;
  injectFormData?: FormDataInjector;
  ViewForm: ReturnType<typeof createFormView>;
}

interface PrePopFormProps extends CreateFormPageArgs {
  prePopForm: PrePopFetcher;
  accountNumberPrimary: string;
}

const PrePopForm: FC<PrePopFormProps> = ({
  ViewForm,
  prePopForm,
  accountNumberPrimary,
}) => {
  const client = useApolloClientWithAuth(config.taxbaseUri);
  const { email } = useAuth() || {};
  const { jurisdictionId } = useJurisdictionContext();
  const [prePopData, setPrePopData] = useState<any>(null);
  const [ncptsRawData, setNcptsRawData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email) return;
    setLoading(true);
    prePopForm({
      email,
      client,
      jurisdictionId,
      accountNumberPrimary,
    })
      .then((data) => {
        if (data) {
          const existentData = data as Record<string, unknown>;
          setPrePopData(existentData?.data);
          setNcptsRawData(existentData?.rawNcpts);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    email,
    client,
    prePopForm,
    setLoading,
    setPrePopData,
    jurisdictionId,
    accountNumberPrimary,
  ]);

  if (loading) {
    return <LoadingPlaceholder />;
  }

  if (!prePopData) return null;

  return (
    <ViewForm
      source="Jurisdiction"
      initialState={prePopData as any}
      rawNcpts={ncptsRawData}
    />
  );
};

const createFormPage = ({
  ViewForm,
  prePopForm,
  injectFormData,
}: CreateFormPageArgs) => {
  const ExistingForm: FC<ExistingFormArgs> = ({ formUuid }) => {
    const { data, loading, formData, currentSubmission } =
      useExistingFormData<FormDataUnion>(
        formUuid,
        GET_MY_FORM,
      );

    const filingYear = data?.form?.filingYear;
    const metadata = useMemo(() => {
      return {
        ...currentSubmission,
        filingYear: filingYear,
        source: data?.form.source,
      };
    }, [filingYear, data, currentSubmission]);

    const source = data?.form?.source;
    const filteredFormData = useMemo(() => {
      if (!formData) return formData;
      const purgedData = deepPurgeValues(null, cleanFormData(formData));
      if (injectFormData) {
        return injectFormData({
          formUuid,
          currentSubmission,
          formData: purgedData,
        });
      }
      return purgedData;
    }, [formData, formUuid, currentSubmission]);

    if (loading) return null;

    return (
      <ViewForm
        source={source}
        formUuid={formUuid}
        metadata={metadata}
        filingYear={filingYear}
        initialState={filteredFormData}
      />
    );
  };

  const FormPage: FC = () => {
    const { formUuid, accountNumberPrimary } = useParams();

    if (formUuid) {
      return <ExistingForm formUuid={formUuid} />;
    }

    if (accountNumberPrimary && prePopForm) {
      return (
        <PrePopForm
          ViewForm={ViewForm}
          prePopForm={prePopForm}
          accountNumberPrimary={accountNumberPrimary}
        />
      );
    }

    return <ViewForm />;
  };

  return FormPage;
};

export default createFormPage;
