import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { TAXSCRIBE_LOGO } from "@taxscribe/images";
import { Card, Col, Container, Row } from "react-bootstrap";
import { shadeColor, useJurisdictionContext } from "@taxscribe/ui";

const StyledCard = styled(Card)`
  color: ${(props) => props.theme.lightText};
  max-width: 400px;
  margin: 0 auto;
  padding: 3rem;
  background-color: ${(props) => shadeColor(props.theme.primary, -10)};
`;

const StyledCardFooter = styled(Card.Footer)`
  border-top: 0px;
  background-color: unset;
`;

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  border-bottom: 0px;
  justify-content: center;
  background-color: unset;
`;

const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const CountyInfo = styled.h3`
  margin-top: 1.5rem;
  margin-bottm: 1.5rem;
  display: flex;
  justify-content: center;
  font-weight: 300;
  text-align: center;
`;

interface JurisdictionAuthCardProps {
  additionalFooterContent?: React.ReactNode;
}

const JurisdictionAuthCard: FC<
  PropsWithChildren<JurisdictionAuthCardProps>
> = ({ children, additionalFooterContent }) => {
  const { name } = useJurisdictionContext();

  return (
    <div>
      <StyledCard>
        <StyledCardHeader>
          <Container>
            <Row>
              <CenteredCol>
                <CountyInfo>
                  <img
                    alt="TaxScribe Logo"
                    src={TAXSCRIBE_LOGO}
                    className="taxscribe-logo img-responsive"
                  />{" "}
                </CountyInfo>
              </CenteredCol>
            </Row>
            <Row>
              <CenteredCol>
                <CountyInfo>
                  {name} <br /> Individual Personal Property
                </CountyInfo>
              </CenteredCol>
            </Row>
          </Container>
        </StyledCardHeader>
        <Card.Body className="text-white py-4">{children}</Card.Body>
        <StyledCardFooter>
          {additionalFooterContent || null}
          <Row>
            <Col>
              <p className="mt-3 text-center">
                Need help? Email&nbsp;
                <a href="mailto:hello@taxscribe.com">
                  <strong>hello@taxscribe.com</strong>
                </a>
                .
              </p>
            </Col>
          </Row>
        </StyledCardFooter>
      </StyledCard>
    </div>
  );
};

export default JurisdictionAuthCard;
