import React, { FC } from "react";
import { Container, Row } from "react-bootstrap";

import LoginForm from "./LoginForm";
import StyledMain from "../../shared/StyledMain";

const Login: FC = () => {
  return (
    <StyledMain>
      <Container fluid="xl">
        <Row>
          <LoginForm />
        </Row>
      </Container>
    </StyledMain>
  );
};

export default Login;
