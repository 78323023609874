import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATE_LOGOS, TAXSCRIBE_IPP_LOGO } from "@taxscribe/images";
import { useStateEfilings } from "@taxscribe/ui";
import React, { FC, useMemo } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import config from "../../../config";

const StateCardStyled = styled(Card)`
  transform: rotate(0);
  margin-bottom: 1rem;
  height: 10rem;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const CardContainer = styled(Container)`
  padding: 1rem;
  border-radius: 1rem;
`;

interface StateCardProps {
  name: string;
  stateAbbr: string;
}

const StateCard: FC<StateCardProps> = ({ name, stateAbbr }) => {
  const link = `/${stateAbbr.toLowerCase()}`;
  const stateLogo = STATE_LOGOS[stateAbbr]?.logo || TAXSCRIBE_IPP_LOGO;

  return (
    <StateCardStyled className="border-light">
      <Card.Body className="d-flex align-items-center ts-primary">
        <img
          src={stateLogo}
          className="img-fluid me-3"
          alt="State"
          width="100"
          loading="lazy"
        />{" "}
        <h2 className="fw-bold">{name}</h2>
        <Link
          to={link}
          className="align-self-center ms-auto stretched-link"
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            aria-hidden="true"
            size="xl"
            className="fw-bold me-2 ts-primary"
          />
        </Link>
      </Card.Body>
    </StateCardStyled>
  );
};

const StateCards: FC = () => {
  const { data } = useStateEfilings(config.formGroup);
  const statesArray = useMemo(() => {
    if (!data) return [];
    return Object.entries(data);
  }, [data]);

  return (
    <>
      <CardContainer className="bg-light">
        {statesArray.map(([state, stateData]) => {
          return (
            <StateCard
              name={state}
              stateAbbr={state}
            />
          );
        })}
      </CardContainer>
    </>
  );
};

export default StateCards;
