import * as Yup from "yup";

const validateRegistration = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string().min(6).required("Required"),
  passwordConfirmation: Yup.string().oneOf([
    Yup.ref("password"),
    null,
  ], "Passwords must match"),
});

export default validateRegistration;
