import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Dashboard from "@taxscribe/dashboard";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormGroupFilingYear, useJurisdictionContext } from "@taxscribe/ui";

import config from "../../../config";
import PageSpinner from "../../shared/PageSpinner";

const longNameStates = ["GA"];

const SearchLink: FC = () => {
  return (
    <Link to="../search">
      <Button
        variant="info"
        className="text-uppercase"
      >
        <FontAwesomeIcon
          icon={faPlus}
          aria-hidden="true"
          className="text-dark pe-2"
        />
        Start New Form
      </Button>
    </Link>
  );
};

const Dash: FC = () => {
  const { stateAbbr, jurisdictionId } = useJurisdictionContext();
  // eslint-disable-next-line
  const [err, { loading, called, filingYear }] = useFormGroupFilingYear({
    formGroup: config.formGroup,
  });

  const useLongName = longNameStates.includes(stateAbbr.toUpperCase());

  if (loading || !called) return <PageSpinner />;

  const currentFilingYear = filingYear || new Date().getFullYear();

  return (
    <>
      <Dashboard
        formPath="form"
        useLongName={useLongName}
        formGroup={config.formGroup}
        jurisdictionId={jurisdictionId}
        currentFilingYear={currentFilingYear}
        additionalHeaderContent={<SearchLink />}
      />
    </>
  );
};

export default Dash;
