import { FormikText } from "@taxscribe/ui";
import styled from "styled-components";


const TransparentFormikField = styled(FormikText)`
  color: ${(props) => props.theme.lightText};
  &&& > input {
    color: ${(props) => props.theme.lightText};
    background-color: transparent;
    :focus {
      background-color: transparent;
    }
  }
`;

export default TransparentFormikField;
