import React, { FC, useCallback } from "react";
import {
  PreviewPdfButton,
  SaveFormButton,
  useFormContext,
  ViewPdfButton,
} from "@taxscribe/form";
import { Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import { NCIPPListingData } from "@taxscribe/form-data-types";
import printIppReview from "@taxscribe/ipp-printer";

interface HeaderActionsProps {
  hasPdf: boolean;
  readOnly?: boolean;
  formUuid?: string;
  metadata?: Record<string, any>;
}

const FormSummaryButton: FC = () => {
  const { metadata } = useFormContext();
  const { values } = useFormikContext<NCIPPListingData>();

  const handleClick = useCallback(() => {
    printIppReview(values, metadata || {});
  }, [values, metadata]);

  return <Button onClick={handleClick}>View Summary</Button>;
};

type FormViewButtonProps = Pick<
  HeaderActionsProps,
  "hasPdf" | "formUuid" | "readOnly" | "metadata"
>;

const FormViewButton: FC<FormViewButtonProps> = ({
  hasPdf,
  metadata,
  formUuid,
}) => {
  if (metadata?.pdfUrl) return <ViewPdfButton uuid={formUuid} />;
  if (hasPdf) return <PreviewPdfButton uuid={formUuid} />;
  return <FormSummaryButton />;
};

const FormHeaderActions: FC<HeaderActionsProps> = (props) => {
  const { readOnly, metadata } = props;
  if (readOnly && metadata?.pdfUrl) {
    return (
      <div className="float-end">
        <span className="px-2">
          <FormViewButton {...props}/>
        </span>
      </div>
    );
  }

  if (readOnly) {
    return (
      <div className="float-end">
        <span className="px-2">
          <FormViewButton {...props}/>
        </span>
      </div>
    );
  }

  return (
    <div className="float-end">
      <span className="px-2">
        <SaveFormButton />
      </span>
      <span className="px-2">
        <FormViewButton {...props}/>
      </span>
    </div>
  );
};

export default FormHeaderActions;
