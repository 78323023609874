import styled from "styled-components";

const StyledMain = styled.main`
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: ${(props) => props.theme.primary};
`;

export default StyledMain;
