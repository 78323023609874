import styled from "styled-components";

const GlobalCss = styled.div`
  .ipp-bg-primary {
    background-color: ${(props) => props?.theme?.primary};
  }

  .btn.btn-info {
    background-color: ${(props) => props?.theme.secondary};
  }

  a {
    color: ${(props) => props?.theme?.lightText};
  }
`;

export default GlobalCss;
