import React, { FC, useCallback } from "react";
import { Button, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useAppToastContext } from "@taxscribe/ui";
import { Form, Formik, useFormikContext } from "formik";
import { useAuth, useSignin } from "@reasongcp/react-fire-sub";

import JurisdictionAuthCard from "../../shared/JurisdictionAuthCard";
import TransparentFormikField from "../../shared/TransparentFormikField";
import CenteredCol from "../../shared/CenteredCol";

const initialValues = {
  email: "",
  password: "",
};

const FormFields: FC = () => {
  const { handleSubmit } = useFormikContext();
  const handleClick = useCallback(
    (e: any) => {
      e?.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <Form>
      <TransparentFormikField
        fieldName="email"
        label="Email"
        placeholder="Email"
      />
      <TransparentFormikField
        fieldName="password"
        label="Password"
        placeholder="Password"
        type="password"
      />
      <Button
        type="submit"
        id="login-btn"
        variant="info"
        className="w-100 mt-4"
        onClick={handleClick}
      >
        Login
      </Button>
    </Form>
  );
};

const errorMap: Record<string, string> = {
  "auth/user-not-found": "Invalid email",
  "auth/wrong-password": "Invalid password",
};

const AdditionalFooterContent: FC = () => {
  return (
    <>
      <Row>
        <CenteredCol className="text-center">
          <p className="mt-3">
            Don&apos;t have an Account?&nbsp;
            <Link
              to="../register"
              className="text-white"
            >
              <strong>Register</strong>
            </Link>
          </p>
        </CenteredCol>
      </Row>
      <Row>
        <CenteredCol>
          <Link to="../forgot-password">Forgot Your Password?</Link>
        </CenteredCol>
      </Row>
    </>
  );
};

const LoginForm: FC = () => {
  const user = useAuth();
  const signin = useSignin();
  const { addToastMessage } = useAppToastContext();

  const handleLogin = useCallback(
    (values: typeof initialValues) => {
      signin(values.email, values.password).catch((e) => {
        const body = errorMap[e.code] || "An error occurred";
        addToastMessage({
          body,
          type: "error",
          header: "Error",
        });
      });
    },
    [signin, addToastMessage],
  );

  if (user?.emailVerified) {
    return <Navigate to="../dashboard" />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleLogin}
      >
        <JurisdictionAuthCard
          additionalFooterContent={<AdditionalFooterContent />}
        >
          <FormFields />
        </JurisdictionAuthCard>
      </Formik>
    </div>
  );
};

export default LoginForm;
