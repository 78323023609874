import { camelCase } from "lodash";
import { config as firebaseQa } from "./qa.firebaseConfig";
import { config as firebaseUat } from "./uat.firebaseConfig";
import { config as firebaseProduction } from "./production.firebaseConfig";

const appEmail = "no-reply@taxscribe.app";

/* eslint no-useless-escape: 0 */
// const previewRegExp =
//   /([^ ]*taxscribe-qa-bpp-app--pr[0-9]{1,3}\-.+?\.\web\.app)/;
//
const hostname = window.location.hostname;
const getCookieName = (): string => {
  const prefix = "pro_auth";
  const domain = hostname.split(".").slice(1).join("_");

  return camelCase(`${prefix}_${domain}`);
};

const detectStage = () => {
  const { hostname } = window.location;

  if (hostname === "ipp.taxscribe.app") {
    return "production";
  }

  if (hostname === "qa-ipp.taxscribe.app") {
    return "qa";
  }

  if (hostname === "uat-ipp.taxscribe.app") {
    return "uat";
  }

  // if (hostname.match(previewRegExp)) {
  //   return "preview";
  // }

  return "development";
};

const configOpts = {
  production: {
    appEmail,
    fireSubApi: "https://sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseProduction,
    authCookieName: getCookieName(),
    taxbaseUri: "https://taxbase.taxscribe.app/",
    bppUri: "https://bpp.taxscribe.app/",
    marketingUri: "https://www.taxscribe.com/",
  },
  uat: {
    appEmail,
    fireSubApi: "https://uat-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseUat,
    authCookieName: getCookieName(),
    taxbaseUri: "https://uat-taxbase.taxscribe.app/",
    bppUri: "https://uat-bpp.taxscribe.app/",
    marketingUri: "https://uat-www.taxscribe.com/",
  },
  qa: {
    appEmail,
    fireSubApi: "https://qa-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseQa,
    authCookieName: getCookieName(),
    taxbaseUri: "https://qa-taxbase.taxscribe.app/",
    bppUri: "https://qa-bpp.taxscribe.app/",
    marketingUri: "https://qa-www.taxscribe.com/",
  },
  development: {
    appEmail,
    firebaseConfig: {
      ...firebaseQa,
      projectId: "taxscribe-dev",
    },
    fireSubApi: "http://localhost:4001/",
    authCookieName: getCookieName(),
    taxbaseUri: "http://localhost:4002/",
    bppUri: "http://localhost:6007",
    marketingUri: "http://localhost:8081/",
  },
};

const formGroup = "IPP";
const releaseStage = detectStage();
const devStages = ["uat", "qa", "preview", "development"];
const devMode = devStages.indexOf(releaseStage) >= 0;
const config = {
  ...configOpts[releaseStage],
  releaseStage,
  devMode,
  formGroup,
};

export default config;
