import { EfilingWithJurisdictionAndFormGroup } from "@taxscribe/gql-queries";
import React, { FC, useMemo } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled(Card)`
  border-radius: 0px;
  color: ${(props) => props.theme.lightTextLight};
  background-color: ${(props) => props.theme.primary};
  align-items: center;
  padding: 3rem 1rem;
`;

const CardContainer = styled(Container)`
  padding: 0rem 1rem 1rem 1rem;
  margin: 0px;
`;

interface JurisdictionLaunchCardProps {
  state: string;
  jurisdictionType: string;
  jurisdictionName: string;
}

const JurisdictionLaunchCard: FC<JurisdictionLaunchCardProps> = ({
  state,
  jurisdictionType,
  jurisdictionName,
}) => {
  return (
    <StyledCard>
      <h5>{jurisdictionName}</h5>
      <Card.Body>
        <Link to={`/${state}/${jurisdictionType}/${jurisdictionName}`}>
          <Button variant="info">Launch</Button>
        </Link>
      </Card.Body>
    </StyledCard>
  );
};

interface JurisdictionLaunchCardsProps {
  state: string;
  efilings: EfilingWithJurisdictionAndFormGroup[];
}

const JurisdictionLaunchCards: FC<JurisdictionLaunchCardsProps> = ({
  state,
  efilings,
}) => {
  const jurisdictions = useMemo(() => {
    return efilings.map((efiling) => {
      return {
        name: efiling.jurisdiction.name,
        jurisdictionType: efiling.jurisdiction.jurisdictionType,
      };
    });
  }, [efilings]);

  return (
    <CardContainer fluid>
      <Row>
        {jurisdictions.map((jurisdiction) => (
          <Col md={3}>
            <JurisdictionLaunchCard
              key={jurisdiction.name + jurisdiction.jurisdictionType}
              state={state}
              jurisdictionName={jurisdiction.name}
              jurisdictionType={jurisdiction.jurisdictionType}
            />
          </Col>
        ))}
      </Row>
    </CardContainer>
  );
};

export default JurisdictionLaunchCards;
