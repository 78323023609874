import { JurisdictionProvider } from "@taxscribe/ui";
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../../pages/Login";
import Dashboard from "../../pages/Dashboard";
import ForgotPassword from "../../pages/ForgotPassword";
import Register from "../../pages/Register";
import Verify from "../../pages/Verify";
import { SSOAuthBoundary } from "@reasongcp/react-fire-sub";
import { gql } from "@apollo/client";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import config from "../../../config";
import LoginRedirect from "../LoginRedirect";
import LoadingPlaceholder from "../LoadingPlaceholder";
import NavBar from "../NavBar";
import Footer from "../Footer";
import TaxrollSearch from "../../pages/TaxrollSearch";
import FormSwitch from "../../pages/Form";
import DashboardRedirect from "../DashboardRedirect";
import VerificationRedirect from "../VerificationRedirect";

const JurisdictionRoutes: FC = () => {
  const firesubClient = useApolloClientWithAuth(config.fireSubApi);
  return (
    <JurisdictionProvider>
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/register"
          element={<Register />}
        />
        <Route
          path="/verify"
          element={<Verify />}
        />
        <Route
          path="/*"
          element={
            <SSOAuthBoundary
              gql={gql}
              client={firesubClient}
              InvalidPlaceHolder={LoginRedirect}
              LoadingPlaceHolder={LoadingPlaceholder}
            >
              <VerificationRedirect>
                <NavBar />
                <Routes>
                  <Route
                    path="/dashboard"
                    element={<Dashboard />}
                  />
                  <Route
                    path="/search"
                    element={<TaxrollSearch />}
                  />
                  <Route
                    path="/form/:formType/new-for/:accountNumberPrimary/*"
                    element={<FormSwitch />}
                  />
                  <Route
                    path="/form/:formType/new/*"
                    element={<FormSwitch />}
                  />
                  <Route
                    path="/form/:formType/:formUuid/*"
                    element={<FormSwitch />}
                  />
                  <Route
                    path="/*"
                    element={<DashboardRedirect />}
                  />
                </Routes>
                <Footer />
              </VerificationRedirect>
            </SSOAuthBoundary>
          }
        />
      </Routes>
    </JurisdictionProvider>
  );
};

export default JurisdictionRoutes;
