import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import appColors from "../../../lib/appColors";
import GlobalCss from "./globalCss";

const theme = {
  ...appColors,
};

const AppTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalCss>
        {children}
      </GlobalCss>
    </ThemeProvider>
  );
};

export default AppTheme;
