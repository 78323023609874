import React, { FC } from "react";
import styled from "styled-components";
import { states } from "@taxscribe/utils";
import { useParams } from "react-router-dom";
import { STATE_LOGOS, TAXSCRIBE_IPP_LOGO } from "@taxscribe/images";

import LandingHeader from "../../shared/LandingHeader";

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledImg = styled.img`
  width: 350px;
  margin-bottom: 1rem;
`;

const StyledEmptyDiv = styled.div`
  height: 500px;
`;

interface IPPInfoProps {
  state?: string;
}

const IPPInfo: FC<IPPInfoProps> = ({ state }) => {
  const stateName: string = states[state?.toUpperCase() || ""] || "";

  return (
    <InfoWrapper>
      <StyledImg
        width="350"
        src={TAXSCRIBE_IPP_LOGO}
        alt="taxscribe-header-logo"
      />
      <h4>
        Filing your {stateName} individual personal property
        online.
      </h4>
    </InfoWrapper>
  );
};

const HeroImage: FC<{ hero: any }> = ({ hero }) => {
  if (!hero) return <StyledEmptyDiv />;
  return (
    <img
      src={hero}
      alt="State Logo"
      width="auto"
      height="auto"
      loading="lazy"
    />
  );
};

const StateBanner: FC = () => {
  const { state } = useParams();
  const hero = STATE_LOGOS[state?.toUpperCase() || ""]?.hero;
  return (
    <LandingHeader
      info={<IPPInfo state={state} />}
      branding={<HeroImage hero={hero} />}
      prompt="SELECT YOUR FILING JURISDICTION:"
    />
  );
};

export default StateBanner;
