import React, { FC } from "react";
import { Container, Row } from "react-bootstrap";
import StyledMain from "../../shared/StyledMain";
import RegistrationForm from "./RegistrationForm";

const Register: FC = () => {
  return (
    <StyledMain>
      <Container fluid="xl">
        <Row>
          <RegistrationForm />
        </Row>
      </Container>
    </StyledMain>
  );
};

export default Register;
