import React, { FC } from "react";

import LandingHeader from "../../shared/LandingHeader";
import landingHeroImage from "../../../images/ipp-state-hero-icon.png";
import appColors from "../../../lib/appColors";
import styled from "styled-components";


const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CenteredH1 = styled.h1`
  text-align: center;
  color: ${appColors.primary};
`;

const StyledHero = styled.img`
  width: 600px;
`;

const HeroImage: FC = () => {
  return (
    <HeroWrapper>
      <StyledHero
        src={landingHeroImage}
        alt="Taxes"
      />
      <CenteredH1>
        Save. Spend. Simple.
      </CenteredH1>
    </HeroWrapper>
  );
};

const PurposeH1 = styled.h1`
  font-weight: 600;
  font-size: 4rem;
  margin-top: 4rem;
`;

const Info: FC = () => {
  return (
    <PurposeH1>
      Filing individual personal property in your state made easy!
    </PurposeH1>
  );
};

const StateSelectHeader: FC = () => {
  return (
    <LandingHeader
      info={<Info />}
      branding={<HeroImage />}
      prompt="SELECT YOUR FILING STATE"
      fontColor={appColors.primary}
      backgroundColor={appColors.altBackground}
    />
  );
};

export default StateSelectHeader;
