import { useJurisdictionContext } from "@taxscribe/ui";
import React, { FC } from "react";
import { Navigate } from "react-router-dom";

const LoginRedirect: FC = () => {
  const { webPath } = useJurisdictionContext();
  return (
    <Navigate to={`/${webPath}/login`} />
  );
};

export default LoginRedirect;
