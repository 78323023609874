import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import JurisdictionSelect from "../../pages/JurisdictionSelect";
import Verify from "../../pages/Verify";
import StateSelector from "../../pages/StateSelect";

import JurisdictionRoutes from "./JurisdictionRoutes";

// Currently we only service NC, so redirect to NC.
// const RedirectToNC: FC = () => {
//   return <Navigate to="/nc" />;
// };

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route
        element={<JurisdictionRoutes />}
        path="/:state/:jurisdictionType/:jurisdictionName/*"
      />
      <Route
        path="/"
        element={<StateSelector />}
      />
      <Route
        path="/verify"
        element={<Verify />}
      />
      <Route
        path="/:state"
        element={<JurisdictionSelect />}
      />
    </Routes>
  );
};

export default AppRoutes;
