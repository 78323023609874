import { gql, useMutation } from "@apollo/client";
import { GET_MY_FORM } from "@taxscribe/gql-queries";
import { useCallback } from "react";

const SUBMIT_FORM = gql`
  mutation SubmitForm($uuid: String!) {
    submitForm(uuid: $uuid, app: TSI) {
      formId
    }
  }
`;

const useSubmitForm = () => {
  const [submit] = useMutation(SUBMIT_FORM, {
    refetchQueries: [GET_MY_FORM],
  });
  const handleSubmit = useCallback((uuid: string) => {
    return submit({ variables: { uuid } });
  }, [submit]);

  return handleSubmit;
};

export default useSubmitForm;
