"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-len */
exports.default = {
    required: [
        "parcelNumber",
        "taxPayerInfo",
        "preparerInfo",
        "propertyLocations",
        "businessInfo",
    ],
    $defs: {
        name: {
            type: "string",
            default: "",
            title: "Common name Schema",
        },
        address: {
            type: "string",
            default: "",
            title: "Common address Schema",
        },
        city: {
            type: "string",
            default: "",
            title: "Common city Schema",
        },
        zipCode: {
            type: "string",
            default: "",
            title: "Common zipCode Schema",
        },
        stateAbbr: {
            type: "string",
            default: "",
            title: "Common stateAbbr Schema",
        },
        phoneNumber: {
            type: "string",
            default: "",
            title: "Common phoneNumber Schema",
        },
        email: {
            type: "string",
            default: "",
            format: "email",
            title: "Common email Schema",
        },
    },
    properties: {
        parcelNumber: {
            $ref: "#/$defs/nonEmptyString",
        },
        taxPayerInfo: {
            type: "object",
            default: {},
            title: "The taxPayerInfo Schema",
            required: [
                "name",
                "address",
                "city",
                "zipCode",
                "stateAbbr",
                "phoneNumber",
                "businessActivity",
            ],
            properties: {
                name: {
                    $ref: "#/$defs/requiredName",
                },
                address: {
                    $ref: "#/$defs/address",
                },
                city: {
                    $ref: "#/$defs/city",
                },
                zipCode: {
                    $ref: "#/$defs/zipCode",
                },
                stateAbbr: {
                    $ref: "#/$defs/stateAbbr",
                },
                phoneNumber: {
                    $ref: "#/$defs/phoneNumber",
                },
                businessActivity: {
                    $ref: "#/$defs/nonEmptyString",
                },
            },
        },
        preparerInfo: {
            type: "object",
            default: {},
            title: "The preparerInfo Schema",
            required: [
                "name",
                "address",
                "city",
                "zipCode",
                "stateAbbr",
                "phoneNumber",
                "email",
            ],
            properties: {
                name: {
                    $ref: "#/$defs/nonEmptyString",
                },
                address: {
                    $ref: "#/$defs/nonEmptyString",
                },
                city: {
                    $ref: "#/$defs/nonEmptyString",
                },
                zipCode: {
                    type: "string",
                    format: "zipCode",
                },
                stateAbbr: {
                    $ref: "#/$defs/stateAbbr",
                },
                phoneNumber: {
                    type: "string",
                    format: "phone",
                },
                email: {
                    type: "string",
                    format: "email",
                },
            },
        },
        propertyLocations: {
            type: "array",
            default: [],
            title: "The propertyLocations Schema",
            items: {
                type: "object",
                default: {},
                title: "A Schema",
                required: ["address", "city", "zipCode", "stateAbbr"],
                properties: {
                    address: {
                        $ref: "#/$defs/address",
                    },
                    city: {
                        $ref: "#/$defs/city",
                    },
                    zipCode: {
                        $ref: "#/$defs/zipCode",
                    },
                    stateAbbr: {
                        $ref: "#/$defs/stateAbbr",
                    },
                },
            },
        },
        businessInfo: {
            type: "object",
            default: {},
            title: "The businessInfo Schema",
            required: [
                "businessType",
                "michiganBusiness",
                // "stateOrganized",
                "naicsCode",
                "businessStartDates",
                "taxpayer",
                "assumedNames",
                "residentialAddress",
                "auditContactInfo",
                "squareFeetOccupied",
            ],
            allOf: [
                {
                    if: {
                        properties: {
                            businessType: {
                                const: "Limited Co.",
                            },
                        },
                    },
                    then: {
                        properties: {
                            miCorporateId: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                        },
                        required: ["miCorporateId"],
                    },
                },
                {
                    if: {
                        properties: {
                            businessType: {
                                const: "Corporation",
                            },
                        },
                    },
                    then: {
                        properties: {
                            miCorporateId: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                        },
                        required: ["miCorporateId"],
                    },
                },
                {
                    if: {
                        properties: {
                            businessType: {
                                const: "Sole Proprietorship",
                            },
                        },
                    },
                    then: {
                        properties: {
                            residentialAddress: {
                                type: "object",
                                title: "The residentialAddress Schema",
                                required: ["address", "city", "zipCode", "stateAbbr"],
                                properties: {
                                    address: {
                                        $ref: "#/$defs/address",
                                    },
                                    city: {
                                        $ref: "#/$defs/city",
                                    },
                                    zipCode: {
                                        $ref: "#/$defs/zipCode",
                                    },
                                    stateAbbr: {
                                        $ref: "#/$defs/stateAbbr",
                                    },
                                },
                            },
                            partnerNames: {
                                type: "array",
                                minItems: 1,
                                default: [],
                                title: "The partnerNames Schema",
                                items: {
                                    type: "object",
                                    properties: {
                                        name: {
                                            type: "string",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    required: ["residentialAddress", "partnerNames"],
                },
                {
                    if: {
                        properties: {
                            businessType: {
                                const: "Partnership",
                            },
                        },
                    },
                    then: {
                        properties: {
                            partnerNames: {
                                type: "array",
                                minItems: 1,
                                default: [],
                                title: "The partnerNames Schema",
                                items: {
                                    properties: {
                                        name: {
                                            $ref: "#/$defs/nonEmptyString",
                                        },
                                    },
                                },
                            },
                        },
                        required: ["partnerNames"],
                    },
                },
            ],
            properties: {
                businessType: {
                    type: "string",
                    default: "",
                    title: "The businessType Schema",
                    enum: [
                        "Sole Proprietorship",
                        "Partnership",
                        "Limited Co.",
                        "Corporation",
                        "Unknown",
                    ],
                },
                miCorporateId: {
                    type: "string",
                    default: "",
                    title: "The miCorporateId Schema",
                },
                michiganBusiness: {
                    type: "boolean",
                    default: false,
                    title: "The michiganBusiness Schema",
                },
                naicsCode: {
                    type: "string",
                    format: "naics",
                },
                businessStartDates: {
                    type: "object",
                    default: {},
                    title: "The businessStartDates Schema",
                    required: ["atLocation", "organizationStartDate"],
                    properties: {
                        atLocation: {
                            type: "string",
                            format: "calendar",
                        },
                        organizationStartDate: {
                            type: "string",
                            format: "calendar",
                        },
                    },
                },
                taxpayer: {
                    type: "object",
                    default: {},
                    title: "The taxpayer Schema",
                    required: ["name"],
                    properties: {
                        name: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                    },
                },
                assumedNames: {
                    type: "array",
                    default: [],
                    title: "The assumedNames Schema",
                    items: {
                        type: "object",
                        default: {},
                        title: "A Schema",
                        required: ["dbaName"],
                        properties: {
                            dbaName: {
                                type: "string",
                            },
                        },
                    },
                },
                auditContactInfo: {
                    type: "object",
                    default: {},
                    title: "The auditContactInfo Schema",
                    required: ["location", "contactName", "phoneNumber"],
                    properties: {
                        location: {
                            type: "object",
                            default: {},
                            title: "The location Schema",
                            required: ["address", "city", "zipCode", "stateAbbr"],
                            properties: {
                                address: {
                                    $ref: "#/$defs/address",
                                },
                                city: {
                                    $ref: "#/$defs/city",
                                },
                                zipCode: {
                                    $ref: "#/$defs/zipCode",
                                },
                                stateAbbr: {
                                    $ref: "#/$defs/stateAbbr",
                                },
                            },
                        },
                        contactName: {
                            type: "string",
                        },
                        phoneNumber: {
                            $ref: "#/$defs/phoneNumber",
                        },
                    },
                },
                squareFeetOccupied: {
                    type: "integer",
                    minimum: 1,
                    title: "The squareFeetOccupied Schema",
                },
            },
        },
    },
};
