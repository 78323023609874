import React, { FC } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

const LoadingPlaceholder: FC = () => (
  <Row>
    <Col className="d-flex justify-content-center mt-5">
      <Spinner
        animation="border"
        variant="secondary"
        role="status"
        style={{ height: "5rem", width: "5rem" }}
      />
    </Col>
  </Row>
);

export default LoadingPlaceholder;
