import { useAuth } from "@reasongcp/react-fire-sub";
import { useAppToastContext } from "@taxscribe/ui";
import { sendEmailVerification } from "firebase/auth";
import React, { FC, SyntheticEvent, useCallback } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CenteredCol from "../../shared/CenteredCol";
import JurisdictionAuthCard from "../../shared/JurisdictionAuthCard";
import LogoutLink from "../../shared/LogoutLink";
import StyledMain from "../../shared/StyledMain";

const StyledButton = styled(Button)`
  width: 100%;
`;

const CenteredP = styled.p`
  text-align: center;
`;

const AdditionalFooterContent: FC = () => {
  const navigate = useNavigate();
  const afterSignout = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <Row>
      <CenteredCol>
        <LogoutLink afterSignOut={afterSignout} />
      </CenteredCol>
    </Row>
  );
};

const Verify: FC = () => {
  const user = useAuth();
  const { addToastMessage } = useAppToastContext();
  const email = user?.email;
  const resendEmail = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (!user) return;
      sendEmailVerification(user).then(() => {
        addToastMessage({
          header: "Success",
          type: "success",
          body: "Verification email resent",
        });
      });
    },
    [user, addToastMessage],
  );

  if (user && user.emailVerified) {
    return <Navigate to="../dashboard" />;
  }

  return (
    <StyledMain>
      <Container fluid="xl">
        <Row>
          <JurisdictionAuthCard
            additionalFooterContent={<AdditionalFooterContent />}
          >
            <Row>
              <CenteredCol>
                <CenteredP>
                  The following email has not been verified:
                </CenteredP>
              </CenteredCol>
            </Row>
            <Row>
              <CenteredCol>
                <CenteredP>
                  <b>{email} </b>
                </CenteredP>
              </CenteredCol>
            </Row>
            <Row>
              <CenteredCol>
                <CenteredP>
                  The verification email should arrive within five minutes, no
                  later than one hour. If it’s been over an hour and the email
                  isn’t in your SPAM folder, please click the button below to
                  resend the verification email.
                </CenteredP>
              </CenteredCol>
            </Row>
            <Row>
              <CenteredCol>
                <StyledButton
                  variant="info"
                  onClick={resendEmail}
                >
                  Resend Verification Email
                </StyledButton>
              </CenteredCol>
            </Row>
          </JurisdictionAuthCard>
        </Row>
      </Container>
    </StyledMain>
  );
};

export default Verify;
