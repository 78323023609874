import React, { FC } from "react";
import { TAXSCRIBE_IPP_LOGO } from "@taxscribe/images";
import { TaxpayerNavBar, useJurisdictionContext } from "@taxscribe/ui";

interface BannerProps {
  name: string;
}

const Banner: FC<BannerProps> = ({ name }) => {
  return (
    <>
      <img
        alt="TaxScribe Logo"
        src={TAXSCRIBE_IPP_LOGO}
      />
      {name} Individual Tax Portal
    </>
  );
};

const NavBar: FC = () => {
  const { webPath, name } = useJurisdictionContext();
  const logoutPath = `/${webPath}/login`;

  return (
    <TaxpayerNavBar
      banner={<Banner name={name} />}
      logoutPath={logoutPath}
    />
  );
};

export default NavBar;
