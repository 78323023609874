import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigateButton } from "@taxscribe/form";
import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";

export interface ConditionalButtonsProps {
  readOnly?: boolean;
}

const ConditionalButtons: FC<ConditionalButtonsProps> = ({ readOnly }) => {
  if (readOnly) return null;

  return (
    <Row className="justify-content-between mt-3">
      <Col>
        <NavigateButton
          saveOnNavigate
          direction="back"
          variant="link"
          className="text-decoration-none fw-bold"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            aria-hidden="true"
            className="me-2"
          />
          Back
        </NavigateButton>
      </Col>
      <Col className="align-self-end">
        <div className="px-2 text-end">
          <NavigateButton
            saveOnNavigate
            variant="outline-primary"
            className="ms-2"
            direction="forward"
          >
            Continue
          </NavigateButton>
        </div>
      </Col>
    </Row>
  );
};

export default ConditionalButtons;
