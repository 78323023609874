"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ippSampleMerged = void 0;
const ippSample06_10_2023_1 = require("./ippSample06-10-2023");
const ippSample11_25_2025_1 = require("./ippSample11-25-2025");
const ipp2025Sample = ippSample11_25_2025_1.ippSample11_25_2025.results[0].Value;
const ipp2023Sample = ippSample06_10_2023_1.ippSample06_10_2023.results[0].Value;
exports.ippSampleMerged = {
    results: [
        {
            AbstractNumber: "",
            Value: Object.assign(Object.assign(Object.assign({}, ipp2025Sample), ipp2023Sample), { OwnershipInformation: Object.assign(Object.assign({}, ipp2025Sample.OwnershipInformation), { Owners: [Object.assign(Object.assign({}, ipp2025Sample.OwnershipInformation.Owners[0]), ipp2023Sample.OwnershipInformation.Owners[0])] }) }),
        },
    ],
};
