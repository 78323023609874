import React, { FC } from "react";
import Taxroll from "@taxscribe/taxroll";
import { useFormGroupFilingYear } from "@taxscribe/ui";

import config from "../../../config";
import PageSpinner from "../../shared/PageSpinner";

const TaxrollSearch: FC = () => {
  const [err, { called, loading, filingYear }] = useFormGroupFilingYear({
    formGroup: config.formGroup,
    fallbackFilingYear: new Date().getFullYear(),
  });

  if (err) {
    console.error(err);
  }

  if (!called || loading) {
    return <PageSpinner />;
  }

  return (
    <Taxroll
      basePath="form"
      filingYear={filingYear}
      formGroup={config.formGroup}
      newFormTooltip="Create a new form"
    />
  );
};

export default TaxrollSearch;
