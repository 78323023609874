import { useSignup } from "@reasongcp/react-fire-sub";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { alterUrlPathTail } from "@taxscribe/utils";

import JurisdictionAuthCard from "../../shared/JurisdictionAuthCard";
import TransparentFormikField from "../../shared/TransparentFormikField";
import validateRegistration from "./validateRegistration";
import { Link, useNavigate } from "react-router-dom";
import { useJurisdictionContext } from "@taxscribe/ui";

const SpacedRow = styled(Row)`
  margin-bottom: 1rem;
`;

interface EmailExistsAlertProps {
  emailInUse?: boolean;
}

const EmailExistsAlert: FC<EmailExistsAlertProps> = ({
  emailInUse,
}) => {
  const { webPath } = useJurisdictionContext();
  if (!emailInUse) return null;

  return (
    <Alert variant="danger">
      <strong>
        That email is already in use. Please{" "}
        <Link
          to={`/${webPath}/forgot-password`}
          className="text-dark"
        >
          reset your password
        </Link>
        .
      </strong>
    </Alert>
  );
};

const FormFields: FC = () => {
  return (
    <Form>
      <SpacedRow>
        <Col>
          <TransparentFormikField
            fieldName="firstName"
            label="First Name*"
            placeholder="First Name"
          />
        </Col>
        <Col>
          <TransparentFormikField
            fieldName="lastName"
            label="Last Name*"
            placeholder="Last Name"
          />
        </Col>
      </SpacedRow>
      <SpacedRow>
        <Col>
          <TransparentFormikField
            fieldName="email"
            label="Email*"
            placeholder="Email"
          />
        </Col>
      </SpacedRow>
      <SpacedRow>
        <Col>
          <TransparentFormikField
            fieldName="password"
            label="Password*"
            placeholder="Password"
            type="password"
          />
        </Col>
        <Col>
          <TransparentFormikField
            fieldName="passwordConfirmation"
            label="Password Confirmation*"
            placeholder="Password Confirmation"
            type="password"
          />
        </Col>
      </SpacedRow>
      <SpacedRow>
        <Col>
          <Button
            type="submit"
            id="login-btn"
            variant="info"
            className="w-100 mt-4"
          >
            Register
          </Button>
        </Col>
      </SpacedRow>
    </Form>
  );
};

const initialState = {
  email: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
};

const errCodeEmailInUse = "auth/email-already-in-use";

const RegistrationForm: FC = () => {
  const navigate = useNavigate();
  const signup = useSignup();
  const [emailInUse, setEmailInUse] = useState(false);

  const handleSignup = useCallback(
    (data: unknown) => {
      if (typeof data !== "object" || !data) {
        console.error("No data provided to signup function");
        return;
      }

      const objData = data as Record<string, string>;
      signup({
        email: objData.email || "",
        password: objData.password || "",
        validationUrl: alterUrlPathTail(window.location.href, "verify"),
      })
        .then(() => navigate("../check-email"))
        .catch((err) => {
          if (err.code && err.code === errCodeEmailInUse) {
            setEmailInUse(true);
          }
        });
    },
    [signup, navigate, setEmailInUse],
  );

  return (
    <JurisdictionAuthCard>
      <Row>
        <EmailExistsAlert emailInUse={emailInUse} />
        <Formik
          onSubmit={handleSignup}
          initialValues={initialState}
          validationSchema={validateRegistration}
        >
          <FormFields />
        </Formik>
      </Row>
    </JurisdictionAuthCard>
  );
};

export default RegistrationForm;
