import React, { Row, Col } from "react-bootstrap";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useJurisdictionContext } from "@taxscribe/ui";
import JurisdictionAuthCard from "../../shared/JurisdictionAuthCard";
import styled from "styled-components";

const StyledP = styled.p`
  color: ${(props) => props.theme.lightText};
  margin-top: 1rem;
`;

const AlreadySent: FC = () => {
  const { webPath } = useJurisdictionContext();

  return (
    <JurisdictionAuthCard
      additionalFooterContent={
        <Row>
          <Col className="text-center">
            <StyledP>
              <Link
                to={`/${webPath}/login`}
              >
                <strong>Back to login</strong>
              </Link>
            </StyledP>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col className="text-center">
          <StyledP>
            If there is an account associated with your email you will receive a
            password reset link in your inbox.
          </StyledP>
        </Col>
      </Row>
    </JurisdictionAuthCard>
  );
};

export default AlreadySent;
