import { EfilingWithJurisdictionAndFormGroup } from "@taxscribe/gql-queries";
import { useStateEfilings } from "@taxscribe/ui";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config";
import JurisdictionLaunchCards from "./JurisdictionLaunchCards";
import StateBanner from "./StateBanner";

const noJurs: EfilingWithJurisdictionAndFormGroup[] = [];

const JurisdictionSelect: FC = () => {
  const { data } = useStateEfilings(config.formGroup);
  const { state } = useParams();
  if (!state) return null;

  const efilings = data[state.toUpperCase()] || noJurs;

  return (
    <>
      <StateBanner />
      <JurisdictionLaunchCards
        state={state}
        efilings={efilings}
      />
    </>
  );
};

export default JurisdictionSelect;
