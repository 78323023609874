import React, { FC, useMemo } from "react";
import { useAuth } from "@reasongcp/react-fire-sub";
import { MultiPageForm, SinglePageForm } from "@taxscribe/form";

import { gql } from "@apollo/client";
import { Col, Row } from "react-bootstrap";
import {
  useCurrentFormPeriod,
  useJurisdictionContext,
  useTaxbaseFormActions,
  useGetFormFieldSignature,
  useToasts,
} from "@taxscribe/ui";
import GenericFormWrapper from "./GenericFormWrapper";
import FormHeaderActions from "./FormHeaderActions";
import ConditionalButtons from "./ConditionalButtons";
import useSubmitForm from "../../../hooks/useSubmitForm";
import { StandardPenaltyBanner } from "@taxscribe/ui";

interface CreateFormViewProps {
  formName: string;
  Form: MultiPageForm | SinglePageForm;
  hasPdf: boolean;
  header: string;
  formType: string;
  // This will often be the same as formName, but not always
  formDataName?: string;
}

const isMultiPageForm = (
  form: MultiPageForm | SinglePageForm,
): form is MultiPageForm => {
  return (form as MultiPageForm).Nav !== undefined;
};

const ifNcpts = (formName: string, content: string) => {
  const isNcptsForm = ["NCIPPListing"].includes(formName);

  if (isNcptsForm) return content;
  return "";
};

// TODO: Type this better
const createFormView = <PropType extends Record<string, any>>({
  Form,
  hasPdf,
  header,
  formName,
  formType,
  formDataName = formName,
}: CreateFormViewProps) => {
  const creationMutationName = `create${formName}`;
  const CREATE_FORM = gql`
    mutation Create${formName}(
      $app: String!
      $businessName: String
      $source: String!
      $jurisdiction: String!
      ${ifNcpts(formName, "$rawNcpts: JSONObject")}
      $formData: ${formDataName}DataInput!
    ) {
      create${formName}(
        app: $app
        source: $source
        formData: $formData
        ${ifNcpts(formName, "ncptsData: $rawNcpts")}
        businessName: $businessName
        jurisdiction: $jurisdiction
      )
    }
  `;

  const UPDATE_FORM = gql`
    mutation Update${formName}(
      $formUuid: String!,
      $formData: ${formDataName}DataInput!,
    ) {
      update${formName}(uuid: $formUuid, formData: $formData)
    }
  `;

  const ViewForm: FC<PropType> = ({
    source,
    rawNcpts,
    formUuid,
    metadata,
    hideSubmit,
    filingYear,
    initialState,
    readOnly: readOnlyOverride,
  }) => {
    const { toastError } = useToasts();
    const currentPeriod = useCurrentFormPeriod();
    const { displayName, email } = useAuth() || {};
    const { name: jurisdictionName, hasEfile } = useJurisdictionContext();
    const submit = useSubmitForm();
    const {
      saveAndSubmit,
      handleSave,
      readOnly: defaultReadOnly,
    } = useTaxbaseFormActions<typeof creationMutationName>({
      source,
      submit,
      formUuid,
      rawNcpts,
      app: "TSI",
      creationMutationName,
      status: metadata?.status,
      updateMutation: UPDATE_FORM,
      createMutation: CREATE_FORM,
      jurisdiction: jurisdictionName,
    });

    const readOnly = readOnlyOverride || defaultReadOnly;

    const handleSignaturePrompt = useGetFormFieldSignature();

    const metadataWithUserInfo = useMemo(() => {
      // Favor the metadata attached to the form submission if it exists.
      if (Object.keys(metadata || {}).length) return metadata;
      return {
        ...metadata,
        source,
        email: metadata?.email || email,
        contactName: displayName || email || "",
        filingYear: currentPeriod?.filingYear,
      };
    }, [currentPeriod, source, metadata, displayName, email]);

    if (isMultiPageForm(Form)) {
      return (
        <Form.Provider
          hasEfile={hasEfile}
          onSave={handleSave}
          readOnly={readOnly}
          errorToast={toastError}
          hideSubmit={hideSubmit}
          onSubmit={saveAndSubmit}
          initialState={initialState}
          metadata={metadataWithUserInfo}
          onSignaturePrompt={handleSignaturePrompt}
        >
          <StandardPenaltyBanner
            readOnly={readOnly}
            shortName={formName}
          />
          <GenericFormWrapper
            noPdf={!hasPdf}
            formUuid={formUuid}
            filingYear={filingYear}
            SideNav={Form.Nav}
            header={header}
            formType={formType}
            headerActions={
              <FormHeaderActions
                hasPdf={hasPdf}
                metadata={metadata}
                formUuid={formUuid}
                readOnly={readOnly}
              />
            }
          >
            <Form.Router />
            <ConditionalButtons readOnly={readOnly} />
          </GenericFormWrapper>
        </Form.Provider>
      );
    }

    const SinglePageForm = Form as SinglePageForm;
    return (
      <SinglePageForm.Provider
        hasEfile={hasEfile}
        onSave={handleSave}
        hideSubmit={hideSubmit}
        onSubmit={saveAndSubmit}
        initialState={initialState}
        readOnly={readOnly}
        metadata={metadataWithUserInfo}
        onSignaturePrompt={handleSignaturePrompt}
      >
        <StandardPenaltyBanner
          readOnly={readOnly}
          shortName={formName}
        />
        <GenericFormWrapper
          header={header}
          formType={formType}
          headerActions={
            <FormHeaderActions
              hasPdf={hasPdf}
              formUuid={formUuid}
              readOnly={readOnly}
              metadata={metadata}
            />
          }
        >
          <Row>
            <Col>
              <SinglePageForm.FormPage />
            </Col>
          </Row>
        </GenericFormWrapper>
      </SinglePageForm.Provider>
    );
  };

  return ViewForm;
};

export default createFormView;
