import React, { FC, ReactNode, useMemo } from "react";
import styled, { ThemeProvider, useTheme } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import appColors from "../../../lib/appColors";

const OuterContainer = styled(Container)`
  background-color: ${(props) => props.theme.background};
  margin-bottom: 3rem;
`;

const StyledContainer = styled(Container)`
  color: ${(props) => props.theme.fontColor};
`;

const SelectTextWrapper = styled.div`
  background-color: ${(props) => props.theme.info};
  color: ${(props) => props.theme.lightTextLight};
  width: 450px;
  margin-bottom: -3rem;
  margin-left: -1rem;
  text-align: center;
  h3 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
`;

interface LandingHeaderProps {
  info: ReactNode;
  branding: ReactNode;
  prompt: ReactNode;
  fontColor?: string;
  backgroundColor?: string;
}

const LandingHeader: FC<LandingHeaderProps> = ({
  info,
  prompt,
  branding,
  fontColor,
  backgroundColor,
}) => {
  const theme = useTheme();
  const customTheme = useMemo(() => {
    const themeAsColors = theme as typeof appColors;
    return {
      ...theme,
      fontColor: fontColor || themeAsColors.lightTextLight,
      background: backgroundColor || themeAsColors.primary,
    };
  }, [theme, fontColor, backgroundColor]);

  return (
    <ThemeProvider theme={customTheme}>
      <OuterContainer fluid>
        <StyledContainer className="col-xxl-11">
          <Row>
            <Col>{info}</Col>
            <Col
              sm={10}
              md={6}
              lg={7}
            >
              {branding}
            </Col>
          </Row>
        </StyledContainer>
        <SelectTextWrapper>
          <h3>{prompt}</h3>
        </SelectTextWrapper>
      </OuterContainer>
    </ThemeProvider>
  );
};

export default LandingHeader;
