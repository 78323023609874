import { NCIPPListing } from "@taxscribe/form";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import { getAuth } from "firebase/auth";
import { merge } from "lodash";
import { fetchNcptsFormData } from "../../../../lib/fetchNcptsFormData";
import createFormPage, { FormDataInjector } from "../../../shared/createFormPage";
import createFormView from "../../../shared/createFormView";

const ViewNCIPPListing = createFormView({
  hasPdf: false,
  Form: NCIPPListing,
  formName: "NCIPPListing",
  header: "NC IPP Listing",
  formType: "IPP Listing",
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const user = getAuth().currentUser;
    const email = user?.email;
    const extraState: Record<string, any> = {
      ncIppListing: { affirmation: { email: email || undefined } },
    };
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const NCIPPListingPage = createFormPage({
  ViewForm: ViewNCIPPListing,
  injectFormData,
  prePopForm: fetchNcptsFormData("IPPListing"),
});

export default NCIPPListingPage;
