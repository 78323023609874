import { shadeColor } from "@taxscribe/ui";

const baseColors = {
  primary: "#37474f", // teal
  info: "#39adc1", // aqua
  lightText: "#bfbfbf", // off white
  altBackground: "#f8f5ef",
};

type BaseColors = typeof baseColors;
type DarkColors = {
  [Property in keyof typeof baseColors as `${Property}Dark`]: string;
};
type LightColors = {
  [Property in keyof typeof baseColors as `${Property}Light`]: string;
};

export interface AppColors extends BaseColors, DarkColors, LightColors {}

const generatedColors = Object.entries(baseColors).reduce((acc, [key, hex]) => {
  const lightKey = `${key}Light`;
  const darkKey = `${key}Dark`;
  const lightHex = shadeColor(hex, 75);
  const darkHex = shadeColor(hex, -75);

  return {
    ...acc,
    [key]: hex,
    [darkKey]: darkHex,
    [lightKey]: lightHex,
  };
}, {}) as AppColors;

// Generated colors can be tweaked here.
const colorOverrides: Partial<AppColors> = {};

const appColors = { ...generatedColors, ...colorOverrides };
export type ColorKey = keyof AppColors;
export default appColors;
