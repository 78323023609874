import { gql } from "@apollo/client";
import { deepPurge, deepPurgeValues } from "@taxscribe/utils";
import { NCExtensionData, NCIPPListingData } from "@taxscribe/form";
import { NC_EXTENSION_DATA, NC_IPP_LISTING_DATA, NC_LISTING_DATA } from "@taxscribe/gql-queries";
import { FetchPrePopDataArgs } from "../components/shared/createFormPage";
import { NCListingData } from "@taxscribe/form-data-types";

// IMPORTANT: This needs to pass the Jurisdiction ID instead of the name and
// Jurisdiction Type.
const GET_NEW_FORM_FOR_BUSINESS = gql`
  ${NC_IPP_LISTING_DATA}
  ${NC_LISTING_DATA}
  ${NC_EXTENSION_DATA}
  query GetNewFormForBusiness(
    $jurisdictionId: Int!
    $formType: NCFormType!
    $accountNumberPrimary: String!
  ) {
    ncptsFormData(
      formType: $formType
      jurisdictionId: $jurisdictionId
      accountNumberPrimary: $accountNumberPrimary
    ) {
      data {
        ... on NCListingData {
          ...NCListingFormData
        }
        ... on NCExtensionData {
          ...NCExtensionFormData
        }
        ... on NCIPPListingData {
          ...NCIPPListingFormData
        }
      }
      rawNcpts
    }
  }
`;

export interface NewFormForBusinessResults {
  ncptsFormData: {
    data: NCListingData | NCExtensionData | NCIPPListingData;
    rawNcpts: any;
  };
}

export const fetchNcptsFormData = (
  formType: "Listing" | "Extension" | "IPPListing",
) => async ({
  email,
  client,
  jurisdictionId,
  accountNumberPrimary,
}: FetchPrePopDataArgs) => {
  const { data } = await client.query<NewFormForBusinessResults>({
    query: GET_NEW_FORM_FOR_BUSINESS,
    variables: { formType, jurisdictionId, accountNumberPrimary },
  });

  if (!data) return null;

  const withoutTypenames = deepPurge(
    "formType",
    deepPurge("__typename", data.ncptsFormData.data),
  );
  const purgedData = deepPurgeValues(null, withoutTypenames);

  return {
    data: purgedData as NCIPPListingData,
    rawNcpts: data.ncptsFormData.rawNcpts,
  };
};
