import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import StateCard from "./StateCards";
import StateSelectHeader from "./StateSelectHeader";

const StateSelector: FC = () => {
  return (
    <>
      <StateSelectHeader />
      <Container>
        <Row>
          <Col>
            <StateCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StateSelector;
