import { DocumentNode, useQuery } from "@apollo/client";
import { CurrentSubmission } from "@taxscribe/gql-queries";
import { omit } from "lodash";

interface ResultData extends Record<string, any> {}

interface Results<Data extends ResultData> {
  form: {
    data: Data;
    source: string;
    filingYear: string;
    currentSubmission: CurrentSubmission;
    formPeriod?: {
      filingDeadline: string;
    };
  };
}

interface UseExistingFormDataResults<Data extends ResultData> {
  loading: boolean;
  data?: Results<Data>;
  formData?: Omit<Data, "__typename">;
  currentSubmission?: CurrentSubmission;
}

const useExistingFormData = <Data extends ResultData>(
  uuid: string,
  query: DocumentNode,
): UseExistingFormDataResults<Data> => {
  const { data, loading } = useQuery<Results<Data>>(query, {
    variables: { uuid },
    fetchPolicy: "network-only",
  });

  const form = data?.form;
  const formData = form?.data ? omit(form.data, ["__typename"]) : undefined;
  const currentSubmission = form?.currentSubmission;

  return { loading: loading, formData, currentSubmission, data };
};

export default useExistingFormData;
