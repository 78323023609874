import React, { FC, PropsWithChildren, ReactNode } from "react";
import { useCurrentNavRoute } from "@taxscribe/form";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  DeadlineGuard,
  GenericDeadlineAlert,
  GenericDeadlinePastPage,
  GenericDeadlinePastPageProps,
  useMostRecentFormPeriodForRoute,
} from "@taxscribe/ui";

import PDFView from "./PDFView";
import PageSpinner from "../PageSpinner";

export interface GenericFormWrapperProps {
  SideNav?: FC;
  header: string;
  noPdf?: boolean;
  formUuid?: string;
  formType: string;
  filingYear?: number | string;
  headerActions?: ReactNode | null;
  FormGuard?: FC<PropsWithChildren>;
  GuardMessage?: ReactNode | FC<GenericDeadlinePastPageProps>;
}

interface FormSegmentHeaderProps {
  header: string;
}

const FormSegmentHeader: FC<FormSegmentHeaderProps> = ({ header }) => {
  const [currentNav] = useCurrentNavRoute() || [];

  if (currentNav) {
    return <h1 className="fw-light h2">{currentNav.text}</h1>;
  }

  return <h1 className="fw-light h2">{header}</h1>;
};

const formatFormName = (name: string) => {
  return name.replace(/ipp /, "IPP ");
};

const GuardMessageWithFormatting: FC<GenericDeadlinePastPageProps> = (
  props,
) => {
  return (
    <GenericDeadlinePastPage
      formatFormName={formatFormName}
      {...props}
    />
  );
};

const GenericFormWrapper: FC<PropsWithChildren<GenericFormWrapperProps>> = ({
  noPdf,
  header,
  SideNav,
  formType,
  formUuid,
  children,
  filingYear,
  headerActions,
  FormGuard = DeadlineGuard,
  GuardMessage = GuardMessageWithFormatting,
}) => {
  const { formPeriod, loading, called } = useMostRecentFormPeriodForRoute({});
  const currentYear = formPeriod?.filingYear || new Date().getFullYear();

  if (!called || loading) {
    return <PageSpinner />;
  }

  if (!noPdf && filingYear && Number(filingYear) !== currentYear) {
    return <PDFView uuid={formUuid || ""} />;
  }

  return (
    <div role="main">
      <Container
        fluid
        className="p-3 mb-5"
      >
        <Row>
          {SideNav && (
            <Col
              sm={2}
              md={3}
            >
              <FormGuard formType={formType}>
                <SideNav />
              </FormGuard>
            </Col>
          )}
          <Col
            sm={SideNav ? 10 : 12}
            md={SideNav ? 9 : 12}
          >
            <Container fluid="xl">
              <FormGuard
                formType={formType}
                guardForExisting
                GuardMessage={GenericDeadlineAlert}
              />
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <FormGuard formType={formType}>{headerActions}</FormGuard>
                      <FormSegmentHeader header={header} />
                    </Card.Header>
                    <FormGuard
                      formType={formType}
                      GuardMessage={GuardMessage}
                    >
                      <Card.Body>{children}</Card.Body>
                    </FormGuard>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenericFormWrapper;
