import React, { Button, Row, Col } from "react-bootstrap";
import { Form, Formik } from "formik";
import { FC, useCallback, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useSUBContext } from "@reasongcp/react-fire-sub";

import AlreadySent from "./AlreadySent";
import JurisdictionAuthCard from "../../shared/JurisdictionAuthCard";
import TransparentFormikField from "../../shared/TransparentFormikField";

interface ResetPasswordPayload {
  email: string;
}

const FormFields: FC = () => {
  return (
    <Form
      id="form-forgot-box"
      autoComplete="off"
    >
      <TransparentFormikField
        label="Email"
        fieldName="email"
        placeholder="Email Address"
      />
      <Button
        type="submit"
        variant="info"
        className="w-100 mt-4"
      >
        Reset Password
      </Button>
    </Form>
  );
};

const initialState = {
  email: "",
};

const ResetPasswordForm: FC = () => {
  const [alreadySent, setAlreadySent] = useState<boolean>(false);
  const { auth } = useSUBContext();
  const handleSubmit = useCallback(({ email }: ResetPasswordPayload) => {
    if (!auth || !email) return;
    sendPasswordResetEmail(auth, email).finally(() => setAlreadySent(true));
  }, [auth]);

  if (alreadySent) {
    return <AlreadySent />;
  }

  return (
    <JurisdictionAuthCard>
      <Row>
        <Col className="text-center">
          <p>We&apos;ll send you instructions to reset your password.</p>
        </Col>
      </Row>
      <Row>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
        >
          <FormFields />
        </Formik>
      </Row>
    </JurisdictionAuthCard>
  );
};

export default ResetPasswordForm;
