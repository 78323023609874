import { useSignOut } from "@reasongcp/react-fire-sub";
import React, { FC, useCallback } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.lightText};
  text-decoration: none;
  &&:hover {
    color: ${(props) => props.theme.lightTextLight};
  }
`;

interface LogoutLinkProps {
  afterSignOut?: () => void;
}

const LogoutLink: FC<LogoutLinkProps> = ({
  afterSignOut,
}) => {
  const signOut = useSignOut();
  const handleClick = useCallback(() => {
    signOut().then(() => afterSignOut ? afterSignOut() : null);
  }, [signOut, afterSignOut]);

  return (
    <StyledButton
      variant="link"
      onClick={handleClick}
    >
      LOGOUT
    </StyledButton>
  );
};

export default LogoutLink;
