import { PT50A } from "@taxscribe/form";
import { fetchPrePopData } from "@taxscribe/ui";
import { deepPurge, deepPurgeValues } from "@taxscribe/utils";
import { getAuth } from "firebase/auth";
import { merge } from "lodash";

import createFormPage, { FormDataInjector } from "../../../shared/createFormPage";
import createFormView from "../../../shared/createFormView";

const ViewIndividualPT50A = createFormView({
  Form: PT50A,
  header: "Individual PT50A",
  formName: "IndividualPT50A",
  formDataName: "PT50A",
  formType: "Individual PT50A",
  hasPdf: true,
});

const clean = (data: Record<any, any>) => {
  return deepPurge("formType", deepPurge("__typename", data));
};

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const user = getAuth().currentUser;
    const email = user?.email;
    const extraState: Record<string, any> = {
      pt50a: { affirmation: { preparerEmail: email || undefined } },
    };
    return deepPurgeValues(null, merge(clean(formData), extraState));
  }

  return deepPurgeValues(null, clean(formData));
};

const IndividualPT50APage = createFormPage({
  injectFormData,
  ViewForm: ViewIndividualPT50A,
  prePopForm: fetchPrePopData("PT50A"),
});

export default IndividualPT50APage;
